import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import './Footer.css';

function Footer() {
    return(
        <div>
            
        <div className="container-fluid special pt-5 mt-5" style={{backgroundColor: 'rgb(129 194 239)'}}>            
            <Grid container direction="row" >
                <Grid item container xs={10} md={5}>
                    <Grid item container xs={12} md={12}>
                        <h3 style={{color: '#000', fontWeight: '600'}}>OFFICE ADDRESS</h3>
                    </Grid>
                    <Grid item container xs={12} md={12} className="mt-3">
                       <p style={{ color: '#000', fontSize: '18px'}}> <LocationOnIcon/> &nbsp; B-002 Skylark Esta, Hoodi, Bangalore- 560048</p>
                    </Grid>
                    <Grid item container xs={12} md={12}>
                        <p><a href="mailto:support@toypebble.com" style={{textDecoration: 'none', color: '#000', fontSize: '18px'}}> <EmailIcon/> &nbsp; support@toypebble.com</a></p>
                    </Grid>
                    <Grid item container xs={12} md={12}>
                        <p><a href="tel:8904299962" style={{textDecoration: 'none', color: '#000', fontSize: '18px'}}> <LocalPhoneIcon/> &nbsp; +91-8904299962</a></p>
                    </Grid>
                </Grid>

                <Grid item container xs={2} md={2}>
                    <Grid item container xs={12} md={12}>
                    <a className="hover" href="https://api.whatsapp.com/send?phone=918904299962" style={{textDecoration: 'none', color: '#000'}}>
                  
                        <WhatsAppIcon style={{color: '#FFF', fontSize: '40px', border: '1px solid rgb(106, 225, 106)', borderRadius: '50%', padding: '6px', backgroundColor: 'rgb(106, 225, 106)'}}/>
                         &nbsp; +91-8904299962
                    </a>
                    </Grid>
                    <Grid item container xs={12} md={12}>
                        <a className="hover" href="https://www.instagram.com/toypebble?igsh=OGg3cXZ3a3VhYzV1" style={{textDecoration: 'none',  color: '#000'}}>                           
                            <InstagramIcon style={{color: 'rgb(226, 62, 117)', fontSize: '35px'}}/>
                            &nbsp; Instagram
                        </a>
                    </Grid>
                    <Grid item container xs={12} md={12}>
                        <a className="hover" href="https://www.facebook.com/share/rYjnfLjopJKX8v34/" style={{textDecoration: 'none',  color: '#000'}}>                          
                            <FacebookIcon style={{color: 'rgb(52, 118, 241)', fontSize: '35px'}}/>
                            &nbsp; Facebook
                        </a>
                    </Grid>
                </Grid>

                <Grid item container xs={6} md={3}>
                    <Grid item container xs={12} md={12}>
                    <Link className="hover" to="/" style={{textDecoration: 'none', color: '#000', fontSize: '18px'}}>
                    Home
                    </Link>
                    </Grid>
                    
                    <Grid item container xs={12} md={12}>
                        <Link className="hover" to="/toys" style={{textDecoration: 'none', color: '#000', fontSize: '18px'}}>
                            Toys
                        </Link>
                    </Grid>
                    <Grid item container xs={12} md={12}>
                        <Link className="hover" to="/ourStory" style={{textDecoration: 'none', color: '#000', fontSize: '18px'}}>
                            Our Story
                        </Link>
                    </Grid>
                    <Grid item container xs={12} md={12}>
                        <Link className="hover" to="/contact" style={{textDecoration: 'none', color: '#000', fontSize: '18px'}}>
                            Contact Us
                        </Link>
                    </Grid>
                </Grid>

                <Grid item container xs={6} md={2} >
  <Grid item container xs={12} md={12}>
    <p style={{ margin: 0, padding: 0, fontSize: '18px' }}>
      <Link className="hover" to="/privacyPolicy" style={{ textDecoration: 'none', color: '#000' }}>
        Privacy Policy
      </Link>
    </p>
  </Grid>
  <Grid item container xs={12} md={12} sx={{margin: 0, padding: 0, fontSize: '18px', position: 'relative', bottom: {
    xs: '0px', // For extra-small screens
    md: '50px',  // For medium screens and up
    lg: '50px'   // For large screens and up
  }}}>
    <p >
      <Link className="hover" to="/termsCondition" style={{ textDecoration: 'none', color: '#000' }}>
        Terms And Conditions
      </Link>
    </p>
  </Grid>
</Grid>

            

                
            </Grid>

        
        
        </div>

        <div className="container-fluid" style={{backgroundColor: 'rgb(15 87 137)'}}>
        <Grid item container direction="row" justifyContent="center" alignItems="center">
            <Grid item container xs={12} md={12}  justifyContent="center" alignItems="center" className="special pt-2 pb-2">
                <h6 class="text-center" style={{color: '#FFF', fontFamily: 'sans-serif'}}>COPYRIGHT @ 2024 MTB RETAIL PRIVATE LIMITED. ALL RIGHTS RESERVED. Designed by <span><a style={{textDecoration: "none", color: "#FFF", fontWeight: "700"}} href="https://www.rpinfocare.com/">RP INFOCARE</a></span></h6>
            </Grid>
        </Grid>
        </div>
        </div>
    )
};

export default Footer;